<template>
  <Banner bg-image="gray-bg.jpg" talk-img="about-suicide.png">
    <template v-slot:title> About suicide</template>
    <template v-slot:subtitle>
      People often have a lot of questions about suicide. Learn the warning
      signs, risk factors, and how to prevent suicide from happening to you or
      someone you know.
    </template>
  </Banner>
  <section class="py-10 gradient-white">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="w-3/4 m-auto text-center">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-4">
          <router-link
            class="btn btn-icon-left"
            :to="{ name: 'SuicideRiskFactors' }"
          >
            <span class="">Suicide risk factors</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              />
            </svg>
          </router-link>

          <router-link
            class="btn btn-icon-left"
            :to="{ name: 'SuicideWarningSigns' }"
          >
            <span>Suicide warning signs</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              />
            </svg>
          </router-link>
          <router-link class="btn btn-icon-left" :to="{ name: 'suicide-FAQ' }">
            <span>Suicide FAQ</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              />
            </svg>
          </router-link>
          <router-link class="btn btn-icon-left" :to="{ name: 'SuicideMyths' }">
            <span>Suicide Myths</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              />
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "AboutSuicide",

  components: {
    Banner,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
.btn-icon-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-width: 5px;
  color: #3c5eaa;
  font-weight: normal;
  font-size: 20px;
  line-height: normal;
  position: relative;
  height: 100px;
  text-align: left;
}
</style>
